import { PageEvent } from '@angular/material/paginator';
import { Action, createReducer, on } from '@ngrx/store';
import { EntityDelta } from '../../shared/models/entity-delta.model';
import * as EntityLogsActions from './entity-logs.actions';
import { Context } from './entity-logs.actions';

export interface EntityLogsState {
  isEntityChangeLogOpened: boolean;
  context: Context;
  entityChanges?: EntityDelta[];
  entitiesLoading: boolean;
  contextLoading: boolean;
  page: PageEvent;
  id: string;
  mappingData?: any;
}

export const initialState: EntityLogsState = {
  isEntityChangeLogOpened: false,
  context: null,
  entityChanges: [],
  entitiesLoading: false,
  contextLoading: false,
  id: null,
  mappingData: null,
  page: {
    pageIndex: 0,
    pageSize: 10,
    previousPageIndex: 0,
    length: 1,
  },
};

export const reducer = createReducer(
  initialState,
  on(EntityLogsActions.entityChangeLogInit, (state, { id, context }) => ({
    ...state,
    entitiesLoading: true,
    contextLoading: true,
    isEntityChangeLogOpened: true,
    id,
    context,
  })),
  on(EntityLogsActions.contextLoaded, state => ({
    ...state,
    contextLoading: false,
  })),
  on(EntityLogsActions.entityChangesFetched, (state, { deltas, total }) => ({
    ...state,
    entitiesLoading: false,
    entityChanges: deltas,
    page: {
      ...state.page,
      length: total,
    },
  })),
  on(EntityLogsActions.entityChangeLogClosed, state => ({
    ...state,
    entityChanges: [],
    isEntityChangeLogOpened: false,
    context: null,
    id: null,
    page: initialState.page,
  })),
  on(EntityLogsActions.entityChangeLogPageChanged, (state, { page }) => ({
    ...state,
    entityChanges: [],
    entitiesLoading: true,
    page,
  })),
  on(EntityLogsActions.vehicleTypesChanged, (state, { vehicleTypes }) => ({
    ...state,
    mappingData: {
      ...state.mappingData,
      vehicleTypes,
    },
  })),
  on(EntityLogsActions.productFeaturesChanged, (state, { productFeatures }) => ({
    ...state,
    mappingData: {
      ...state.mappingData,
      productFeatures,
    },
  })),
  on(EntityLogsActions.dispatchTagChanged, (state, { tags }) => ({
    ...state,
    mappingData: {
      ...state.mappingData,
      dispatchTags: tags,
    },
  })),
  on(EntityLogsActions.partnersChanged, (state, { partners }) => ({
    ...state,
    mappingData: {
      ...state.mappingData,
      partners,
    },
  })),
  on(EntityLogsActions.areasChanged, (state, { areas }) => ({
    ...state,
    mappingData: {
      ...state.mappingData,
      areas,
    },
  })),
  on(EntityLogsActions.partnerTransportLicensesChanged, (state, { licenses }) => ({
    ...state,
    mappingData: {
      ...state.mappingData,
      partnerTransportLicenses: licenses,
    },
  })),
);

export function entityLogsReducer(state: EntityLogsState | undefined, action: Action) {
  return reducer(state, action);
}
